/*!
 * Isotope PACKAGED v3.0.5
 *
 * Licensed GPLv3 for open source use
 * or Isotope Commercial License for commercial use
 *
 * https://isotope.metafizzy.co
 * Copyright 2017 Metafizzy
 */

!(function (t, e) {
	'function' === typeof define && define.amd
		? define('jquery-bridget/jquery-bridget', ['jquery'], function (i) {
				return e(t, i);
		  })
		: 'object' === typeof module && module.exports
		? (module.exports = e(t, require('jquery')))
		: (t.jQueryBridget = e(t, t.jQuery));
})(window, function (t, e) {
	'use strict';
	function i(i, s, a) {
		function u(t, e, o) {
			let n,
				s = '$().' + i + '("' + e + '")';
			return (
				t.each(function (t, u) {
					const h = a.data(u, i);
					if (!h)
						return void r(
							i +
								' not initialized. Cannot call methods, i.e. ' +
								s
						);
					const d = h[e];
					if (!d || '_' == e.charAt(0))
						return void r(s + ' is not a valid method');
					const l = d.apply(h, o);
					n = void 0 === n ? l : n;
				}),
				void 0 !== n ? n : t
			);
		}
		function h(t, e) {
			t.each(function (t, o) {
				let n = a.data(o, i);
				n
					? (n.option(e), n._init())
					: ((n = new s(o, e)), a.data(o, i, n));
			});
		}
		(a = a || e || t.jQuery),
			a &&
				(s.prototype.option ||
					(s.prototype.option = function (t) {
						a.isPlainObject(t) &&
							(this.options = a.extend(!0, this.options, t));
					}),
				(a.fn[i] = function (t) {
					if ('string' === typeof t) {
						const e = n.call(arguments, 1);
						return u(this, t, e);
					}
					return h(this, t), this;
				}),
				o(a));
	}
	function o(t) {
		!t || (t && t.bridget) || (t.bridget = i);
	}
	var n = Array.prototype.slice,
		s = t.console,
		r =
			'undefined' === typeof s
				? function () {}
				: function (t) {
						s.error(t);
				  };
	return o(e || t.jQuery), i;
}),
	(function (t, e) {
		'function' === typeof define && define.amd
			? define('ev-emitter/ev-emitter', e)
			: 'object' === typeof module && module.exports
			? (module.exports = e())
			: (t.EvEmitter = e());
	})('undefined' !== typeof window ? window : this, function () {
		function t() {}
		const e = t.prototype;
		return (
			(e.on = function (t, e) {
				if (t && e) {
					const i = (this._events = this._events || {}),
						o = (i[t] = i[t] || []);
					return o.indexOf(e) == -1 && o.push(e), this;
				}
			}),
			(e.once = function (t, e) {
				if (t && e) {
					this.on(t, e);
					const i = (this._onceEvents = this._onceEvents || {}),
						o = (i[t] = i[t] || {});
					return (o[e] = !0), this;
				}
			}),
			(e.off = function (t, e) {
				const i = this._events && this._events[t];
				if (i && i.length) {
					const o = i.indexOf(e);
					return o != -1 && i.splice(o, 1), this;
				}
			}),
			(e.emitEvent = function (t, e) {
				let i = this._events && this._events[t];
				if (i && i.length) {
					(i = i.slice(0)), (e = e || []);
					for (
						let o = this._onceEvents && this._onceEvents[t], n = 0;
						n < i.length;
						n++
					) {
						const s = i[n],
							r = o && o[s];
						r && (this.off(t, s), delete o[s]), s.apply(this, e);
					}
					return this;
				}
			}),
			(e.allOff = function () {
				delete this._events, delete this._onceEvents;
			}),
			t
		);
	}),
	(function (t, e) {
		'use strict';
		'function' === typeof define && define.amd
			? define('get-size/get-size', [], function () {
					return e();
			  })
			: 'object' === typeof module && module.exports
			? (module.exports = e())
			: (t.getSize = e());
	})(window, function () {
		'use strict';
		function t(t) {
			const e = parseFloat(t),
				i = t.indexOf('%') == -1 && !isNaN(e);
			return i && e;
		}
		function e() {}
		function i() {
			for (
				var t = {
						width: 0,
						height: 0,
						innerWidth: 0,
						innerHeight: 0,
						outerWidth: 0,
						outerHeight: 0,
					},
					e = 0;
				e < h;
				e++
			) {
				const i = u[e];
				t[i] = 0;
			}
			return t;
		}
		function o(t) {
			const e = getComputedStyle(t);
			return (
				e ||
					a(
						'Style returned ' +
							e +
							'. Are you running this code in a hidden iframe on Firefox? See http://bit.ly/getsizebug1'
					),
				e
			);
		}
		function n() {
			if (!d) {
				d = !0;
				const e = document.createElement('div');
				(e.style.width = '200px'),
					(e.style.padding = '1px 2px 3px 4px'),
					(e.style.borderStyle = 'solid'),
					(e.style.borderWidth = '1px 2px 3px 4px'),
					(e.style.boxSizing = 'border-box');
				const i = document.body || document.documentElement;
				i.appendChild(e);
				const n = o(e);
				(s.isBoxSizeOuter = r = 200 == t(n.width)), i.removeChild(e);
			}
		}
		function s(e) {
			if (
				(n(),
				'string' === typeof e && (e = document.querySelector(e)),
				e && 'object' === typeof e && e.nodeType)
			) {
				const s = o(e);
				if ('none' == s.display) return i();
				const a = {};
				(a.width = e.offsetWidth), (a.height = e.offsetHeight);
				for (
					var d = (a.isBorderBox = 'border-box' == s.boxSizing),
						l = 0;
					l < h;
					l++
				) {
					const f = u[l],
						c = s[f],
						m = parseFloat(c);
					a[f] = isNaN(m) ? 0 : m;
				}
				const p = a.paddingLeft + a.paddingRight,
					y = a.paddingTop + a.paddingBottom,
					g = a.marginLeft + a.marginRight,
					v = a.marginTop + a.marginBottom,
					_ = a.borderLeftWidth + a.borderRightWidth,
					I = a.borderTopWidth + a.borderBottomWidth,
					z = d && r,
					x = t(s.width);
				x !== !1 && (a.width = x + (z ? 0 : p + _));
				const S = t(s.height);
				return (
					S !== !1 && (a.height = S + (z ? 0 : y + I)),
					(a.innerWidth = a.width - (p + _)),
					(a.innerHeight = a.height - (y + I)),
					(a.outerWidth = a.width + g),
					(a.outerHeight = a.height + v),
					a
				);
			}
		}
		var r,
			a =
				'undefined' === typeof console
					? e
					: function (t) {
							console.error(t);
					  },
			u = [
				'paddingLeft',
				'paddingRight',
				'paddingTop',
				'paddingBottom',
				'marginLeft',
				'marginRight',
				'marginTop',
				'marginBottom',
				'borderLeftWidth',
				'borderRightWidth',
				'borderTopWidth',
				'borderBottomWidth',
			],
			h = u.length,
			d = !1;
		return s;
	}),
	(function (t, e) {
		'use strict';
		'function' === typeof define && define.amd
			? define('desandro-matches-selector/matches-selector', e)
			: 'object' === typeof module && module.exports
			? (module.exports = e())
			: (t.matchesSelector = e());
	})(window, function () {
		'use strict';
		const t = (function () {
			const t = window.Element.prototype;
			if (t.matches) return 'matches';
			if (t.matchesSelector) return 'matchesSelector';
			for (
				let e = ['webkit', 'moz', 'ms', 'o'], i = 0;
				i < e.length;
				i++
			) {
				const o = e[i],
					n = o + 'MatchesSelector';
				if (t[n]) return n;
			}
		})();
		return function (e, i) {
			return e[t](i);
		};
	}),
	(function (t, e) {
		'function' === typeof define && define.amd
			? define('fizzy-ui-utils/utils', [
					'desandro-matches-selector/matches-selector',
			  ], function (i) {
					return e(t, i);
			  })
			: 'object' === typeof module && module.exports
			? (module.exports = e(t, require('desandro-matches-selector')))
			: (t.fizzyUIUtils = e(t, t.matchesSelector));
	})(window, function (t, e) {
		const i = {};
		(i.extend = function (t, e) {
			for (const i in e) t[i] = e[i];
			return t;
		}),
			(i.modulo = function (t, e) {
				return ((t % e) + e) % e;
			}),
			(i.makeArray = function (t) {
				let e = [];
				if (Array.isArray(t)) e = t;
				else if (
					t &&
					'object' === typeof t &&
					'number' === typeof t.length
				)
					for (let i = 0; i < t.length; i++) e.push(t[i]);
				else e.push(t);
				return e;
			}),
			(i.removeFrom = function (t, e) {
				const i = t.indexOf(e);
				i != -1 && t.splice(i, 1);
			}),
			(i.getParent = function (t, i) {
				for (; t.parentNode && t != document.body; )
					if (((t = t.parentNode), e(t, i))) return t;
			}),
			(i.getQueryElement = function (t) {
				return 'string' === typeof t ? document.querySelector(t) : t;
			}),
			(i.handleEvent = function (t) {
				const e = 'on' + t.type;
				this[e] && this[e](t);
			}),
			(i.filterFindElements = function (t, o) {
				t = i.makeArray(t);
				const n = [];
				return (
					t.forEach(function (t) {
						if (t instanceof HTMLElement) {
							if (!o) return void n.push(t);
							e(t, o) && n.push(t);
							for (
								let i = t.querySelectorAll(o), s = 0;
								s < i.length;
								s++
							)
								n.push(i[s]);
						}
					}),
					n
				);
			}),
			(i.debounceMethod = function (t, e, i) {
				const o = t.prototype[e],
					n = e + 'Timeout';
				t.prototype[e] = function () {
					const t = this[n];
					t && clearTimeout(t);
					const e = arguments,
						s = this;
					this[n] = setTimeout(function () {
						o.apply(s, e), delete s[n];
					}, i || 100);
				};
			}),
			(i.docReady = function (t) {
				const e = document.readyState;
				'complete' == e || 'interactive' == e
					? setTimeout(t)
					: document.addEventListener('DOMContentLoaded', t);
			}),
			(i.toDashed = function (t) {
				return t
					.replace(/(.)([A-Z])/g, function (t, e, i) {
						return e + '-' + i;
					})
					.toLowerCase();
			});
		const o = t.console;
		return (
			(i.htmlInit = function (e, n) {
				i.docReady(function () {
					const s = i.toDashed(n),
						r = 'data-' + s,
						a = document.querySelectorAll('[' + r + ']'),
						u = document.querySelectorAll('.js-' + s),
						h = i.makeArray(a).concat(i.makeArray(u)),
						d = r + '-options',
						l = t.jQuery;
					h.forEach(function (t) {
						let i,
							s = t.getAttribute(r) || t.getAttribute(d);
						try {
							i = s && JSON.parse(s);
						} catch (a) {
							return void (
								o &&
								o.error(
									'Error parsing ' +
										r +
										' on ' +
										t.className +
										': ' +
										a
								)
							);
						}
						const u = new e(t, i);
						l && l.data(t, n, u);
					});
				});
			}),
			i
		);
	}),
	(function (t, e) {
		'function' === typeof define && define.amd
			? define('outlayer/item', [
					'ev-emitter/ev-emitter',
					'get-size/get-size',
			  ], e)
			: 'object' === typeof module && module.exports
			? (module.exports = e(require('ev-emitter'), require('get-size')))
			: ((t.Outlayer = {}),
			  (t.Outlayer.Item = e(t.EvEmitter, t.getSize)));
	})(window, function (t, e) {
		'use strict';
		function i(t) {
			for (var e in t) return !1;
			return (e = null), !0;
		}
		function o(t, e) {
			t &&
				((this.element = t),
				(this.layout = e),
				(this.position = { x: 0, y: 0 }),
				this._create());
		}
		function n(t) {
			return t.replace(/([A-Z])/g, function (t) {
				return '-' + t.toLowerCase();
			});
		}
		const s = document.documentElement.style,
			r =
				'string' === typeof s.transition
					? 'transition'
					: 'WebkitTransition',
			a =
				'string' === typeof s.transform
					? 'transform'
					: 'WebkitTransform',
			u = {
				WebkitTransition: 'webkitTransitionEnd',
				transition: 'transitionend',
			}[r],
			h = {
				transform: a,
				transition: r,
				transitionDuration: r + 'Duration',
				transitionProperty: r + 'Property',
				transitionDelay: r + 'Delay',
			},
			d = (o.prototype = Object.create(t.prototype));
		(d.constructor = o),
			(d._create = function () {
				(this._transn = { ingProperties: {}, clean: {}, onEnd: {} }),
					this.css({ position: 'absolute' });
			}),
			(d.handleEvent = function (t) {
				const e = 'on' + t.type;
				this[e] && this[e](t);
			}),
			(d.getSize = function () {
				this.size = e(this.element);
			}),
			(d.css = function (t) {
				const e = this.element.style;
				for (const i in t) {
					const o = h[i] || i;
					e[o] = t[i];
				}
			}),
			(d.getPosition = function () {
				let t = getComputedStyle(this.element),
					e = this.layout._getOption('originLeft'),
					i = this.layout._getOption('originTop'),
					o = t[e ? 'left' : 'right'],
					n = t[i ? 'top' : 'bottom'],
					s = this.layout.size,
					r =
						o.indexOf('%') != -1
							? (parseFloat(o) / 100) * s.width
							: parseInt(o, 10),
					a =
						n.indexOf('%') != -1
							? (parseFloat(n) / 100) * s.height
							: parseInt(n, 10);
				(r = isNaN(r) ? 0 : r),
					(a = isNaN(a) ? 0 : a),
					(r -= e ? s.paddingLeft : s.paddingRight),
					(a -= i ? s.paddingTop : s.paddingBottom),
					(this.position.x = r),
					(this.position.y = a);
			}),
			(d.layoutPosition = function () {
				const t = this.layout.size,
					e = {},
					i = this.layout._getOption('originLeft'),
					o = this.layout._getOption('originTop'),
					n = i ? 'paddingLeft' : 'paddingRight',
					s = i ? 'left' : 'right',
					r = i ? 'right' : 'left',
					a = this.position.x + t[n];
				(e[s] = this.getXValue(a)), (e[r] = '');
				const u = o ? 'paddingTop' : 'paddingBottom',
					h = o ? 'top' : 'bottom',
					d = o ? 'bottom' : 'top',
					l = this.position.y + t[u];
				(e[h] = this.getYValue(l)),
					(e[d] = ''),
					this.css(e),
					this.emitEvent('layout', [this]);
			}),
			(d.getXValue = function (t) {
				const e = this.layout._getOption('horizontal');
				return this.layout.options.percentPosition && !e
					? (t / this.layout.size.width) * 100 + '%'
					: t + 'px';
			}),
			(d.getYValue = function (t) {
				const e = this.layout._getOption('horizontal');
				return this.layout.options.percentPosition && e
					? (t / this.layout.size.height) * 100 + '%'
					: t + 'px';
			}),
			(d._transitionTo = function (t, e) {
				this.getPosition();
				const i = this.position.x,
					o = this.position.y,
					n = parseInt(t, 10),
					s = parseInt(e, 10),
					r = n === this.position.x && s === this.position.y;
				if ((this.setPosition(t, e), r && !this.isTransitioning))
					return void this.layoutPosition();
				const a = t - i,
					u = e - o,
					h = {};
				(h.transform = this.getTranslate(a, u)),
					this.transition({
						to: h,
						onTransitionEnd: { transform: this.layoutPosition },
						isCleaning: !0,
					});
			}),
			(d.getTranslate = function (t, e) {
				const i = this.layout._getOption('originLeft'),
					o = this.layout._getOption('originTop');
				return (
					(t = i ? t : -t),
					(e = o ? e : -e),
					'translate3d(' + t + 'px, ' + e + 'px, 0)'
				);
			}),
			(d.goTo = function (t, e) {
				this.setPosition(t, e), this.layoutPosition();
			}),
			(d.moveTo = d._transitionTo),
			(d.setPosition = function (t, e) {
				(this.position.x = parseInt(t, 10)),
					(this.position.y = parseInt(e, 10));
			}),
			(d._nonTransition = function (t) {
				this.css(t.to), t.isCleaning && this._removeStyles(t.to);
				for (const e in t.onTransitionEnd)
					t.onTransitionEnd[e].call(this);
			}),
			(d.transition = function (t) {
				if (!parseFloat(this.layout.options.transitionDuration))
					return void this._nonTransition(t);
				const e = this._transn;
				for (var i in t.onTransitionEnd)
					e.onEnd[i] = t.onTransitionEnd[i];
				for (i in t.to)
					(e.ingProperties[i] = !0),
						t.isCleaning && (e.clean[i] = !0);
				if (t.from) {
					this.css(t.from);
					let o = this.element.offsetHeight;
					o = null;
				}
				this.enableTransition(t.to),
					this.css(t.to),
					(this.isTransitioning = !0);
			});
		const l = 'opacity,' + n(a);
		(d.enableTransition = function () {
			if (!this.isTransitioning) {
				let t = this.layout.options.transitionDuration;
				(t = 'number' === typeof t ? t + 'ms' : t),
					this.css({
						transitionProperty: l,
						transitionDuration: t,
						transitionDelay: this.staggerDelay || 0,
					}),
					this.element.addEventListener(u, this, !1);
			}
		}),
			(d.onwebkitTransitionEnd = function (t) {
				this.ontransitionend(t);
			}),
			(d.onotransitionend = function (t) {
				this.ontransitionend(t);
			});
		const f = { '-webkit-transform': 'transform' };
		(d.ontransitionend = function (t) {
			if (t.target === this.element) {
				const e = this._transn,
					o = f[t.propertyName] || t.propertyName;
				if (
					(delete e.ingProperties[o],
					i(e.ingProperties) && this.disableTransition(),
					o in e.clean &&
						((this.element.style[t.propertyName] = ''),
						delete e.clean[o]),
					o in e.onEnd)
				) {
					const n = e.onEnd[o];
					n.call(this), delete e.onEnd[o];
				}
				this.emitEvent('transitionEnd', [this]);
			}
		}),
			(d.disableTransition = function () {
				this.removeTransitionStyles(),
					this.element.removeEventListener(u, this, !1),
					(this.isTransitioning = !1);
			}),
			(d._removeStyles = function (t) {
				const e = {};
				for (const i in t) e[i] = '';
				this.css(e);
			});
		const c = {
			transitionProperty: '',
			transitionDuration: '',
			transitionDelay: '',
		};
		return (
			(d.removeTransitionStyles = function () {
				this.css(c);
			}),
			(d.stagger = function (t) {
				(t = isNaN(t) ? 0 : t), (this.staggerDelay = t + 'ms');
			}),
			(d.removeElem = function () {
				this.element.parentNode.removeChild(this.element),
					this.css({ display: '' }),
					this.emitEvent('remove', [this]);
			}),
			(d.remove = function () {
				return r && parseFloat(this.layout.options.transitionDuration)
					? (this.once('transitionEnd', function () {
							this.removeElem();
					  }),
					  void this.hide())
					: void this.removeElem();
			}),
			(d.reveal = function () {
				delete this.isHidden, this.css({ display: '' });
				const t = this.layout.options,
					e = {},
					i = this.getHideRevealTransitionEndProperty('visibleStyle');
				(e[i] = this.onRevealTransitionEnd),
					this.transition({
						from: t.hiddenStyle,
						to: t.visibleStyle,
						isCleaning: !0,
						onTransitionEnd: e,
					});
			}),
			(d.onRevealTransitionEnd = function () {
				this.isHidden || this.emitEvent('reveal');
			}),
			(d.getHideRevealTransitionEndProperty = function (t) {
				const e = this.layout.options[t];
				if (e.opacity) return 'opacity';
				for (const i in e) return i;
			}),
			(d.hide = function () {
				(this.isHidden = !0), this.css({ display: '' });
				const t = this.layout.options,
					e = {},
					i = this.getHideRevealTransitionEndProperty('hiddenStyle');
				(e[i] = this.onHideTransitionEnd),
					this.transition({
						from: t.visibleStyle,
						to: t.hiddenStyle,
						isCleaning: !0,
						onTransitionEnd: e,
					});
			}),
			(d.onHideTransitionEnd = function () {
				this.isHidden &&
					(this.css({ display: 'none' }), this.emitEvent('hide'));
			}),
			(d.destroy = function () {
				this.css({
					position: '',
					left: '',
					right: '',
					top: '',
					bottom: '',
					transition: '',
					transform: '',
				});
			}),
			o
		);
	}),
	(function (t, e) {
		'use strict';
		'function' === typeof define && define.amd
			? define('outlayer/outlayer', [
					'ev-emitter/ev-emitter',
					'get-size/get-size',
					'fizzy-ui-utils/utils',
					'./item',
			  ], function (i, o, n, s) {
					return e(t, i, o, n, s);
			  })
			: 'object' === typeof module && module.exports
			? (module.exports = e(
					t,
					require('ev-emitter'),
					require('get-size'),
					require('fizzy-ui-utils'),
					require('./item')
			  ))
			: (t.Outlayer = e(
					t,
					t.EvEmitter,
					t.getSize,
					t.fizzyUIUtils,
					t.Outlayer.Item
			  ));
	})(window, function (t, e, i, o, n) {
		'use strict';
		function s(t, e) {
			const i = o.getQueryElement(t);
			if (!i)
				return void (
					u &&
					u.error(
						'Bad element for ' +
							this.constructor.namespace +
							': ' +
							(i || t)
					)
				);
			(this.element = i),
				h && (this.$element = h(this.element)),
				(this.options = o.extend({}, this.constructor.defaults)),
				this.option(e);
			const n = ++l;
			(this.element.outlayerGUID = n), (f[n] = this), this._create();
			const s = this._getOption('initLayout');
			s && this.layout();
		}
		function r(t) {
			function e() {
				t.apply(this, arguments);
			}
			return (
				(e.prototype = Object.create(t.prototype)),
				(e.prototype.constructor = e),
				e
			);
		}
		function a(t) {
			if ('number' === typeof t) return t;
			let e = t.match(/(^\d*\.?\d*)(\w*)/),
				i = e && e[1],
				o = e && e[2];
			if (!i.length) return 0;
			i = parseFloat(i);
			const n = m[o] || 1;
			return i * n;
		}
		var u = t.console,
			h = t.jQuery,
			d = function () {},
			l = 0,
			f = {};
		(s.namespace = 'outlayer'),
			(s.Item = n),
			(s.defaults = {
				containerStyle: { position: 'relative' },
				initLayout: !0,
				originLeft: !0,
				originTop: !0,
				resize: !0,
				resizeContainer: !0,
				transitionDuration: '0.4s',
				hiddenStyle: { opacity: 0, transform: 'scale(0.001)' },
				visibleStyle: { opacity: 1, transform: 'scale(1)' },
			});
		const c = s.prototype;
		o.extend(c, e.prototype),
			(c.option = function (t) {
				o.extend(this.options, t);
			}),
			(c._getOption = function (t) {
				const e = this.constructor.compatOptions[t];
				return e && void 0 !== this.options[e]
					? this.options[e]
					: this.options[t];
			}),
			(s.compatOptions = {
				initLayout: 'isInitLayout',
				horizontal: 'isHorizontal',
				layoutInstant: 'isLayoutInstant',
				originLeft: 'isOriginLeft',
				originTop: 'isOriginTop',
				resize: 'isResizeBound',
				resizeContainer: 'isResizingContainer',
			}),
			(c._create = function () {
				this.reloadItems(),
					(this.stamps = []),
					this.stamp(this.options.stamp),
					o.extend(this.element.style, this.options.containerStyle);
				const t = this._getOption('resize');
				t && this.bindResize();
			}),
			(c.reloadItems = function () {
				this.items = this._itemize(this.element.children);
			}),
			(c._itemize = function (t) {
				for (
					var e = this._filterFindItemElements(t),
						i = this.constructor.Item,
						o = [],
						n = 0;
					n < e.length;
					n++
				) {
					const s = e[n],
						r = new i(s, this);
					o.push(r);
				}
				return o;
			}),
			(c._filterFindItemElements = function (t) {
				return o.filterFindElements(t, this.options.itemSelector);
			}),
			(c.getItemElements = function () {
				return this.items.map(function (t) {
					return t.element;
				});
			}),
			(c.layout = function () {
				this._resetLayout(), this._manageStamps();
				const t = this._getOption('layoutInstant'),
					e = void 0 !== t ? t : !this._isLayoutInited;
				this.layoutItems(this.items, e), (this._isLayoutInited = !0);
			}),
			(c._init = c.layout),
			(c._resetLayout = function () {
				this.getSize();
			}),
			(c.getSize = function () {
				this.size = i(this.element);
			}),
			(c._getMeasurement = function (t, e) {
				let o,
					n = this.options[t];
				n
					? ('string' === typeof n
							? (o = this.element.querySelector(n))
							: n instanceof HTMLElement && (o = n),
					  (this[t] = o ? i(o)[e] : n))
					: (this[t] = 0);
			}),
			(c.layoutItems = function (t, e) {
				(t = this._getItemsForLayout(t)),
					this._layoutItems(t, e),
					this._postLayout();
			}),
			(c._getItemsForLayout = function (t) {
				return t.filter(function (t) {
					return !t.isIgnored;
				});
			}),
			(c._layoutItems = function (t, e) {
				if ((this._emitCompleteOnItems('layout', t), t && t.length)) {
					const i = [];
					t.forEach(function (t) {
						const o = this._getItemLayoutPosition(t);
						(o.item = t),
							(o.isInstant = e || t.isLayoutInstant),
							i.push(o);
					}, this),
						this._processLayoutQueue(i);
				}
			}),
			(c._getItemLayoutPosition = function () {
				return { x: 0, y: 0 };
			}),
			(c._processLayoutQueue = function (t) {
				this.updateStagger(),
					t.forEach(function (t, e) {
						this._positionItem(t.item, t.x, t.y, t.isInstant, e);
					}, this);
			}),
			(c.updateStagger = function () {
				const t = this.options.stagger;
				return null === t || void 0 === t
					? void (this.stagger = 0)
					: ((this.stagger = a(t)), this.stagger);
			}),
			(c._positionItem = function (t, e, i, o, n) {
				o
					? t.goTo(e, i)
					: (t.stagger(n * this.stagger), t.moveTo(e, i));
			}),
			(c._postLayout = function () {
				this.resizeContainer();
			}),
			(c.resizeContainer = function () {
				const t = this._getOption('resizeContainer');
				if (t) {
					const e = this._getContainerSize();
					e &&
						(this._setContainerMeasure(e.width, !0),
						this._setContainerMeasure(e.height, !1));
				}
			}),
			(c._getContainerSize = d),
			(c._setContainerMeasure = function (t, e) {
				if (void 0 !== t) {
					const i = this.size;
					i.isBorderBox &&
						(t += e
							? i.paddingLeft +
							  i.paddingRight +
							  i.borderLeftWidth +
							  i.borderRightWidth
							: i.paddingBottom +
							  i.paddingTop +
							  i.borderTopWidth +
							  i.borderBottomWidth),
						(t = Math.max(t, 0)),
						(this.element.style[e ? 'width' : 'height'] = t + 'px');
				}
			}),
			(c._emitCompleteOnItems = function (t, e) {
				function i() {
					n.dispatchEvent(t + 'Complete', null, [e]);
				}
				function o() {
					r++, r == s && i();
				}
				var n = this,
					s = e.length;
				if (!e || !s) return void i();
				var r = 0;
				e.forEach(function (e) {
					e.once(t, o);
				});
			}),
			(c.dispatchEvent = function (t, e, i) {
				const o = e ? [e].concat(i) : i;
				if ((this.emitEvent(t, o), h))
					if (
						((this.$element = this.$element || h(this.element)), e)
					) {
						const n = h.Event(e);
						(n.type = t), this.$element.trigger(n, i);
					} else this.$element.trigger(t, i);
			}),
			(c.ignore = function (t) {
				const e = this.getItem(t);
				e && (e.isIgnored = !0);
			}),
			(c.unignore = function (t) {
				const e = this.getItem(t);
				e && delete e.isIgnored;
			}),
			(c.stamp = function (t) {
				(t = this._find(t)),
					t &&
						((this.stamps = this.stamps.concat(t)),
						t.forEach(this.ignore, this));
			}),
			(c.unstamp = function (t) {
				(t = this._find(t)),
					t &&
						t.forEach(function (t) {
							o.removeFrom(this.stamps, t), this.unignore(t);
						}, this);
			}),
			(c._find = function (t) {
				if (t)
					return (
						'string' === typeof t &&
							(t = this.element.querySelectorAll(t)),
						(t = o.makeArray(t))
					);
			}),
			(c._manageStamps = function () {
				this.stamps &&
					this.stamps.length &&
					(this._getBoundingRect(),
					this.stamps.forEach(this._manageStamp, this));
			}),
			(c._getBoundingRect = function () {
				const t = this.element.getBoundingClientRect(),
					e = this.size;
				this._boundingRect = {
					left: t.left + e.paddingLeft + e.borderLeftWidth,
					top: t.top + e.paddingTop + e.borderTopWidth,
					right: t.right - (e.paddingRight + e.borderRightWidth),
					bottom: t.bottom - (e.paddingBottom + e.borderBottomWidth),
				};
			}),
			(c._manageStamp = d),
			(c._getElementOffset = function (t) {
				const e = t.getBoundingClientRect(),
					o = this._boundingRect,
					n = i(t),
					s = {
						left: e.left - o.left - n.marginLeft,
						top: e.top - o.top - n.marginTop,
						right: o.right - e.right - n.marginRight,
						bottom: o.bottom - e.bottom - n.marginBottom,
					};
				return s;
			}),
			(c.handleEvent = o.handleEvent),
			(c.bindResize = function () {
				t.addEventListener('resize', this), (this.isResizeBound = !0);
			}),
			(c.unbindResize = function () {
				t.removeEventListener('resize', this),
					(this.isResizeBound = !1);
			}),
			(c.onresize = function () {
				this.resize();
			}),
			o.debounceMethod(s, 'onresize', 100),
			(c.resize = function () {
				this.isResizeBound && this.needsResizeLayout() && this.layout();
			}),
			(c.needsResizeLayout = function () {
				const t = i(this.element),
					e = this.size && t;
				return e && t.innerWidth !== this.size.innerWidth;
			}),
			(c.addItems = function (t) {
				const e = this._itemize(t);
				return e.length && (this.items = this.items.concat(e)), e;
			}),
			(c.appended = function (t) {
				const e = this.addItems(t);
				e.length && (this.layoutItems(e, !0), this.reveal(e));
			}),
			(c.prepended = function (t) {
				const e = this._itemize(t);
				if (e.length) {
					const i = this.items.slice(0);
					(this.items = e.concat(i)),
						this._resetLayout(),
						this._manageStamps(),
						this.layoutItems(e, !0),
						this.reveal(e),
						this.layoutItems(i);
				}
			}),
			(c.reveal = function (t) {
				if ((this._emitCompleteOnItems('reveal', t), t && t.length)) {
					const e = this.updateStagger();
					t.forEach(function (t, i) {
						t.stagger(i * e), t.reveal();
					});
				}
			}),
			(c.hide = function (t) {
				if ((this._emitCompleteOnItems('hide', t), t && t.length)) {
					const e = this.updateStagger();
					t.forEach(function (t, i) {
						t.stagger(i * e), t.hide();
					});
				}
			}),
			(c.revealItemElements = function (t) {
				const e = this.getItems(t);
				this.reveal(e);
			}),
			(c.hideItemElements = function (t) {
				const e = this.getItems(t);
				this.hide(e);
			}),
			(c.getItem = function (t) {
				for (let e = 0; e < this.items.length; e++) {
					const i = this.items[e];
					if (i.element == t) return i;
				}
			}),
			(c.getItems = function (t) {
				t = o.makeArray(t);
				const e = [];
				return (
					t.forEach(function (t) {
						const i = this.getItem(t);
						i && e.push(i);
					}, this),
					e
				);
			}),
			(c.remove = function (t) {
				const e = this.getItems(t);
				this._emitCompleteOnItems('remove', e),
					e &&
						e.length &&
						e.forEach(function (t) {
							t.remove(), o.removeFrom(this.items, t);
						}, this);
			}),
			(c.destroy = function () {
				const t = this.element.style;
				(t.height = ''),
					(t.position = ''),
					(t.width = ''),
					this.items.forEach(function (t) {
						t.destroy();
					}),
					this.unbindResize();
				const e = this.element.outlayerGUID;
				delete f[e],
					delete this.element.outlayerGUID,
					h && h.removeData(this.element, this.constructor.namespace);
			}),
			(s.data = function (t) {
				t = o.getQueryElement(t);
				const e = t && t.outlayerGUID;
				return e && f[e];
			}),
			(s.create = function (t, e) {
				const i = r(s);
				return (
					(i.defaults = o.extend({}, s.defaults)),
					o.extend(i.defaults, e),
					(i.compatOptions = o.extend({}, s.compatOptions)),
					(i.namespace = t),
					(i.data = s.data),
					(i.Item = r(n)),
					o.htmlInit(i, t),
					h && h.bridget && h.bridget(t, i),
					i
				);
			});
		var m = { ms: 1, s: 1e3 };
		return (s.Item = n), s;
	}),
	(function (t, e) {
		'function' === typeof define && define.amd
			? define('isotope-layout/js/item', ['outlayer/outlayer'], e)
			: 'object' === typeof module && module.exports
			? (module.exports = e(require('outlayer')))
			: ((t.Isotope = t.Isotope || {}), (t.Isotope.Item = e(t.Outlayer)));
	})(window, function (t) {
		'use strict';
		function e() {
			t.Item.apply(this, arguments);
		}
		const i = (e.prototype = Object.create(t.Item.prototype)),
			o = i._create;
		(i._create = function () {
			(this.id = this.layout.itemGUID++),
				o.call(this),
				(this.sortData = {});
		}),
			(i.updateSortData = function () {
				if (!this.isIgnored) {
					(this.sortData.id = this.id),
						(this.sortData['original-order'] = this.id),
						(this.sortData.random = Math.random());
					const t = this.layout.options.getSortData,
						e = this.layout._sorters;
					for (const i in t) {
						const o = e[i];
						this.sortData[i] = o(this.element, this);
					}
				}
			});
		const n = i.destroy;
		return (
			(i.destroy = function () {
				n.apply(this, arguments), this.css({ display: '' });
			}),
			e
		);
	}),
	(function (t, e) {
		'function' === typeof define && define.amd
			? define('isotope-layout/js/layout-mode', [
					'get-size/get-size',
					'outlayer/outlayer',
			  ], e)
			: 'object' === typeof module && module.exports
			? (module.exports = e(require('get-size'), require('outlayer')))
			: ((t.Isotope = t.Isotope || {}),
			  (t.Isotope.LayoutMode = e(t.getSize, t.Outlayer)));
	})(window, function (t, e) {
		'use strict';
		function i(t) {
			(this.isotope = t),
				t &&
					((this.options = t.options[this.namespace]),
					(this.element = t.element),
					(this.items = t.filteredItems),
					(this.size = t.size));
		}
		const o = i.prototype,
			n = [
				'_resetLayout',
				'_getItemLayoutPosition',
				'_manageStamp',
				'_getContainerSize',
				'_getElementOffset',
				'needsResizeLayout',
				'_getOption',
			];
		return (
			n.forEach(function (t) {
				o[t] = function () {
					return e.prototype[t].apply(this.isotope, arguments);
				};
			}),
			(o.needsVerticalResizeLayout = function () {
				const e = t(this.isotope.element),
					i = this.isotope.size && e;
				return i && e.innerHeight != this.isotope.size.innerHeight;
			}),
			(o._getMeasurement = function () {
				this.isotope._getMeasurement.apply(this, arguments);
			}),
			(o.getColumnWidth = function () {
				this.getSegmentSize('column', 'Width');
			}),
			(o.getRowHeight = function () {
				this.getSegmentSize('row', 'Height');
			}),
			(o.getSegmentSize = function (t, e) {
				const i = t + e,
					o = 'outer' + e;
				if ((this._getMeasurement(i, o), !this[i])) {
					const n = this.getFirstItemSize();
					this[i] = (n && n[o]) || this.isotope.size['inner' + e];
				}
			}),
			(o.getFirstItemSize = function () {
				const e = this.isotope.filteredItems[0];
				return e && e.element && t(e.element);
			}),
			(o.layout = function () {
				this.isotope.layout.apply(this.isotope, arguments);
			}),
			(o.getSize = function () {
				this.isotope.getSize(), (this.size = this.isotope.size);
			}),
			(i.modes = {}),
			(i.create = function (t, e) {
				function n() {
					i.apply(this, arguments);
				}
				return (
					(n.prototype = Object.create(o)),
					(n.prototype.constructor = n),
					e && (n.options = e),
					(n.prototype.namespace = t),
					(i.modes[t] = n),
					n
				);
			}),
			i
		);
	}),
	(function (t, e) {
		'function' === typeof define && define.amd
			? define('masonry-layout/masonry', [
					'outlayer/outlayer',
					'get-size/get-size',
			  ], e)
			: 'object' === typeof module && module.exports
			? (module.exports = e(require('outlayer'), require('get-size')))
			: (t.Masonry = e(t.Outlayer, t.getSize));
	})(window, function (t, e) {
		const i = t.create('masonry');
		i.compatOptions.fitWidth = 'isFitWidth';
		const o = i.prototype;
		return (
			(o._resetLayout = function () {
				this.getSize(),
					this._getMeasurement('columnWidth', 'outerWidth'),
					this._getMeasurement('gutter', 'outerWidth'),
					this.measureColumns(),
					(this.colYs = []);
				for (let t = 0; t < this.cols; t++) this.colYs.push(0);
				(this.maxY = 0), (this.horizontalColIndex = 0);
			}),
			(o.measureColumns = function () {
				if ((this.getContainerWidth(), !this.columnWidth)) {
					const t = this.items[0],
						i = t && t.element;
					this.columnWidth =
						(i && e(i).outerWidth) || this.containerWidth;
				}
				let o = (this.columnWidth += this.gutter),
					n = this.containerWidth + this.gutter,
					s = n / o,
					r = o - (n % o),
					a = r && r < 1 ? 'round' : 'floor';
				(s = Math[a](s)), (this.cols = Math.max(s, 1));
			}),
			(o.getContainerWidth = function () {
				const t = this._getOption('fitWidth'),
					i = t ? this.element.parentNode : this.element,
					o = e(i);
				this.containerWidth = o && o.innerWidth;
			}),
			(o._getItemLayoutPosition = function (t) {
				t.getSize();
				let e = t.size.outerWidth % this.columnWidth,
					i = e && e < 1 ? 'round' : 'ceil',
					o = Math[i](t.size.outerWidth / this.columnWidth);
				o = Math.min(o, this.cols);
				for (
					var n = this.options.horizontalOrder
							? '_getHorizontalColPosition'
							: '_getTopColPosition',
						s = this[n](o, t),
						r = { x: this.columnWidth * s.col, y: s.y },
						a = s.y + t.size.outerHeight,
						u = o + s.col,
						h = s.col;
					h < u;
					h++
				)
					this.colYs[h] = a;
				return r;
			}),
			(o._getTopColPosition = function (t) {
				const e = this._getTopColGroup(t),
					i = Math.min.apply(Math, e);
				return { col: e.indexOf(i), y: i };
			}),
			(o._getTopColGroup = function (t) {
				if (t < 2) return this.colYs;
				for (var e = [], i = this.cols + 1 - t, o = 0; o < i; o++)
					e[o] = this._getColGroupY(o, t);
				return e;
			}),
			(o._getColGroupY = function (t, e) {
				if (e < 2) return this.colYs[t];
				const i = this.colYs.slice(t, t + e);
				return Math.max.apply(Math, i);
			}),
			(o._getHorizontalColPosition = function (t, e) {
				let i = this.horizontalColIndex % this.cols,
					o = t > 1 && i + t > this.cols;
				i = o ? 0 : i;
				const n = e.size.outerWidth && e.size.outerHeight;
				return (
					(this.horizontalColIndex = n
						? i + t
						: this.horizontalColIndex),
					{ col: i, y: this._getColGroupY(i, t) }
				);
			}),
			(o._manageStamp = function (t) {
				let i = e(t),
					o = this._getElementOffset(t),
					n = this._getOption('originLeft'),
					s = n ? o.left : o.right,
					r = s + i.outerWidth,
					a = Math.floor(s / this.columnWidth);
				a = Math.max(0, a);
				let u = Math.floor(r / this.columnWidth);
				(u -= r % this.columnWidth ? 0 : 1),
					(u = Math.min(this.cols - 1, u));
				for (
					let h = this._getOption('originTop'),
						d = (h ? o.top : o.bottom) + i.outerHeight,
						l = a;
					l <= u;
					l++
				)
					this.colYs[l] = Math.max(d, this.colYs[l]);
			}),
			(o._getContainerSize = function () {
				this.maxY = Math.max.apply(Math, this.colYs);
				const t = { height: this.maxY };
				return (
					this._getOption('fitWidth') &&
						(t.width = this._getContainerFitWidth()),
					t
				);
			}),
			(o._getContainerFitWidth = function () {
				for (var t = 0, e = this.cols; --e && 0 === this.colYs[e]; )
					t++;
				return (this.cols - t) * this.columnWidth - this.gutter;
			}),
			(o.needsResizeLayout = function () {
				const t = this.containerWidth;
				return this.getContainerWidth(), t != this.containerWidth;
			}),
			i
		);
	}),
	(function (t, e) {
		'function' === typeof define && define.amd
			? define('isotope-layout/js/layout-modes/masonry', [
					'../layout-mode',
					'masonry-layout/masonry',
			  ], e)
			: 'object' === typeof module && module.exports
			? (module.exports = e(
					require('../layout-mode'),
					require('masonry-layout')
			  ))
			: e(t.Isotope.LayoutMode, t.Masonry);
	})(window, function (t, e) {
		'use strict';
		const i = t.create('masonry'),
			o = i.prototype,
			n = { _getElementOffset: !0, layout: !0, _getMeasurement: !0 };
		for (const s in e.prototype) n[s] || (o[s] = e.prototype[s]);
		const r = o.measureColumns;
		o.measureColumns = function () {
			(this.items = this.isotope.filteredItems), r.call(this);
		};
		const a = o._getOption;
		return (
			(o._getOption = function (t) {
				return 'fitWidth' == t
					? void 0 !== this.options.isFitWidth
						? this.options.isFitWidth
						: this.options.fitWidth
					: a.apply(this.isotope, arguments);
			}),
			i
		);
	}),
	(function (t, e) {
		'function' === typeof define && define.amd
			? define('isotope-layout/js/layout-modes/fit-rows', [
					'../layout-mode',
			  ], e)
			: 'object' === typeof exports
			? (module.exports = e(require('../layout-mode')))
			: e(t.Isotope.LayoutMode);
	})(window, function (t) {
		'use strict';
		const e = t.create('fitRows'),
			i = e.prototype;
		return (
			(i._resetLayout = function () {
				(this.x = 0),
					(this.y = 0),
					(this.maxY = 0),
					this._getMeasurement('gutter', 'outerWidth');
			}),
			(i._getItemLayoutPosition = function (t) {
				t.getSize();
				const e = t.size.outerWidth + this.gutter,
					i = this.isotope.size.innerWidth + this.gutter;
				0 !== this.x &&
					e + this.x > i &&
					((this.x = 0), (this.y = this.maxY));
				const o = { x: this.x, y: this.y };
				return (
					(this.maxY = Math.max(
						this.maxY,
						this.y + t.size.outerHeight
					)),
					(this.x += e),
					o
				);
			}),
			(i._getContainerSize = function () {
				return { height: this.maxY };
			}),
			e
		);
	}),
	(function (t, e) {
		'function' === typeof define && define.amd
			? define('isotope-layout/js/layout-modes/vertical', [
					'../layout-mode',
			  ], e)
			: 'object' === typeof module && module.exports
			? (module.exports = e(require('../layout-mode')))
			: e(t.Isotope.LayoutMode);
	})(window, function (t) {
		'use strict';
		const e = t.create('vertical', { horizontalAlignment: 0 }),
			i = e.prototype;
		return (
			(i._resetLayout = function () {
				this.y = 0;
			}),
			(i._getItemLayoutPosition = function (t) {
				t.getSize();
				const e =
						(this.isotope.size.innerWidth - t.size.outerWidth) *
						this.options.horizontalAlignment,
					i = this.y;
				return (this.y += t.size.outerHeight), { x: e, y: i };
			}),
			(i._getContainerSize = function () {
				return { height: this.y };
			}),
			e
		);
	}),
	(function (t, e) {
		'function' === typeof define && define.amd
			? define([
					'outlayer/outlayer',
					'get-size/get-size',
					'desandro-matches-selector/matches-selector',
					'fizzy-ui-utils/utils',
					'isotope-layout/js/item',
					'isotope-layout/js/layout-mode',
					'isotope-layout/js/layout-modes/masonry',
					'isotope-layout/js/layout-modes/fit-rows',
					'isotope-layout/js/layout-modes/vertical',
			  ], function (i, o, n, s, r, a) {
					return e(t, i, o, n, s, r, a);
			  })
			: 'object' === typeof module && module.exports
			? (module.exports = e(
					t,
					require('outlayer'),
					require('get-size'),
					require('desandro-matches-selector'),
					require('fizzy-ui-utils'),
					require('isotope-layout/js/item'),
					require('isotope-layout/js/layout-mode'),
					require('isotope-layout/js/layout-modes/masonry'),
					require('isotope-layout/js/layout-modes/fit-rows'),
					require('isotope-layout/js/layout-modes/vertical')
			  ))
			: (t.Isotope = e(
					t,
					t.Outlayer,
					t.getSize,
					t.matchesSelector,
					t.fizzyUIUtils,
					t.Isotope.Item,
					t.Isotope.LayoutMode
			  ));
	})(window, function (t, e, i, o, n, s, r) {
		function a(t, e) {
			return function (i, o) {
				for (let n = 0; n < t.length; n++) {
					const s = t[n],
						r = i.sortData[s],
						a = o.sortData[s];
					if (r > a || r < a) {
						const u = void 0 !== e[s] ? e[s] : e,
							h = u ? 1 : -1;
						return (r > a ? 1 : -1) * h;
					}
				}
				return 0;
			};
		}
		const u = t.jQuery,
			h = String.prototype.trim
				? function (t) {
						return t.trim();
				  }
				: function (t) {
						return t.replace(/^\s+|\s+$/g, '');
				  },
			d = e.create('isotope', {
				layoutMode: 'masonry',
				isJQueryFiltering: !0,
				sortAscending: !0,
			});
		(d.Item = s), (d.LayoutMode = r);
		const l = d.prototype;
		(l._create = function () {
			(this.itemGUID = 0),
				(this._sorters = {}),
				this._getSorters(),
				e.prototype._create.call(this),
				(this.modes = {}),
				(this.filteredItems = this.items),
				(this.sortHistory = ['original-order']);
			for (const t in r.modes) this._initLayoutMode(t);
		}),
			(l.reloadItems = function () {
				(this.itemGUID = 0), e.prototype.reloadItems.call(this);
			}),
			(l._itemize = function () {
				for (
					var t = e.prototype._itemize.apply(this, arguments), i = 0;
					i < t.length;
					i++
				) {
					const o = t[i];
					o.id = this.itemGUID++;
				}
				return this._updateItemsSortData(t), t;
			}),
			(l._initLayoutMode = function (t) {
				const e = r.modes[t],
					i = this.options[t] || {};
				(this.options[t] = e.options ? n.extend(e.options, i) : i),
					(this.modes[t] = new e(this));
			}),
			(l.layout = function () {
				return !this._isLayoutInited && this._getOption('initLayout')
					? void this.arrange()
					: void this._layout();
			}),
			(l._layout = function () {
				const t = this._getIsInstant();
				this._resetLayout(),
					this._manageStamps(),
					this.layoutItems(this.filteredItems, t),
					(this._isLayoutInited = !0);
			}),
			(l.arrange = function (t) {
				this.option(t), this._getIsInstant();
				const e = this._filter(this.items);
				(this.filteredItems = e.matches),
					this._bindArrangeComplete(),
					this._isInstant
						? this._noTransition(this._hideReveal, [e])
						: this._hideReveal(e),
					this._sort(),
					this._layout();
			}),
			(l._init = l.arrange),
			(l._hideReveal = function (t) {
				this.reveal(t.needReveal), this.hide(t.needHide);
			}),
			(l._getIsInstant = function () {
				const t = this._getOption('layoutInstant'),
					e = void 0 !== t ? t : !this._isLayoutInited;
				return (this._isInstant = e), e;
			}),
			(l._bindArrangeComplete = function () {
				function t() {
					e &&
						i &&
						o &&
						n.dispatchEvent('arrangeComplete', null, [
							n.filteredItems,
						]);
				}
				var e,
					i,
					o,
					n = this;
				this.once('layoutComplete', function () {
					(e = !0), t();
				}),
					this.once('hideComplete', function () {
						(i = !0), t();
					}),
					this.once('revealComplete', function () {
						(o = !0), t();
					});
			}),
			(l._filter = function (t) {
				let e = this.options.filter;
				e = e || '*';
				for (
					var i = [],
						o = [],
						n = [],
						s = this._getFilterTest(e),
						r = 0;
					r < t.length;
					r++
				) {
					const a = t[r];
					if (!a.isIgnored) {
						const u = s(a);
						u && i.push(a),
							u && a.isHidden
								? o.push(a)
								: u || a.isHidden || n.push(a);
					}
				}
				return { matches: i, needReveal: o, needHide: n };
			}),
			(l._getFilterTest = function (t) {
				return u && this.options.isJQueryFiltering
					? function (e) {
							return u(e.element).is(t);
					  }
					: 'function' === typeof t
					? function (e) {
							return t(e.element);
					  }
					: function (e) {
							return o(e.element, t);
					  };
			}),
			(l.updateSortData = function (t) {
				let e;
				t
					? ((t = n.makeArray(t)), (e = this.getItems(t)))
					: (e = this.items),
					this._getSorters(),
					this._updateItemsSortData(e);
			}),
			(l._getSorters = function () {
				const t = this.options.getSortData;
				for (const e in t) {
					const i = t[e];
					this._sorters[e] = f(i);
				}
			}),
			(l._updateItemsSortData = function (t) {
				for (let e = t && t.length, i = 0; e && i < e; i++) {
					const o = t[i];
					o.updateSortData();
				}
			});
		var f = (function () {
			function t(t) {
				if ('string' !== typeof t) return t;
				const i = h(t).split(' '),
					o = i[0],
					n = o.match(/^\[(.+)\]$/),
					s = n && n[1],
					r = e(s, o),
					a = d.sortDataParsers[i[1]];
				return (t = a
					? function (t) {
							return t && a(r(t));
					  }
					: function (t) {
							return t && r(t);
					  });
			}
			function e(t, e) {
				return t
					? function (e) {
							return e.getAttribute(t);
					  }
					: function (t) {
							const i = t.querySelector(e);
							return i && i.textContent;
					  };
			}
			return t;
		})();
		(d.sortDataParsers = {
			parseInt(t) {
				return parseInt(t, 10);
			},
			parseFloat(t) {
				return parseFloat(t);
			},
		}),
			(l._sort = function () {
				if (this.options.sortBy) {
					const t = n.makeArray(this.options.sortBy);
					this._getIsSameSortBy(t) ||
						(this.sortHistory = t.concat(this.sortHistory));
					const e = a(this.sortHistory, this.options.sortAscending);
					this.filteredItems.sort(e);
				}
			}),
			(l._getIsSameSortBy = function (t) {
				for (let e = 0; e < t.length; e++)
					if (t[e] != this.sortHistory[e]) return !1;
				return !0;
			}),
			(l._mode = function () {
				const t = this.options.layoutMode,
					e = this.modes[t];
				if (!e) throw new Error('No layout mode: ' + t);
				return (e.options = this.options[t]), e;
			}),
			(l._resetLayout = function () {
				e.prototype._resetLayout.call(this),
					this._mode()._resetLayout();
			}),
			(l._getItemLayoutPosition = function (t) {
				return this._mode()._getItemLayoutPosition(t);
			}),
			(l._manageStamp = function (t) {
				this._mode()._manageStamp(t);
			}),
			(l._getContainerSize = function () {
				return this._mode()._getContainerSize();
			}),
			(l.needsResizeLayout = function () {
				return this._mode().needsResizeLayout();
			}),
			(l.appended = function (t) {
				const e = this.addItems(t);
				if (e.length) {
					const i = this._filterRevealAdded(e);
					this.filteredItems = this.filteredItems.concat(i);
				}
			}),
			(l.prepended = function (t) {
				const e = this._itemize(t);
				if (e.length) {
					this._resetLayout(), this._manageStamps();
					const i = this._filterRevealAdded(e);
					this.layoutItems(this.filteredItems),
						(this.filteredItems = i.concat(this.filteredItems)),
						(this.items = e.concat(this.items));
				}
			}),
			(l._filterRevealAdded = function (t) {
				const e = this._filter(t);
				return (
					this.hide(e.needHide),
					this.reveal(e.matches),
					this.layoutItems(e.matches, !0),
					e.matches
				);
			}),
			(l.insert = function (t) {
				const e = this.addItems(t);
				if (e.length) {
					let i,
						o,
						n = e.length;
					for (i = 0; i < n; i++)
						(o = e[i]), this.element.appendChild(o.element);
					const s = this._filter(e).matches;
					for (i = 0; i < n; i++) e[i].isLayoutInstant = !0;
					for (this.arrange(), i = 0; i < n; i++)
						delete e[i].isLayoutInstant;
					this.reveal(s);
				}
			});
		const c = l.remove;
		return (
			(l.remove = function (t) {
				t = n.makeArray(t);
				const e = this.getItems(t);
				c.call(this, t);
				for (let i = e && e.length, o = 0; i && o < i; o++) {
					const s = e[o];
					n.removeFrom(this.filteredItems, s);
				}
			}),
			(l.shuffle = function () {
				for (let t = 0; t < this.items.length; t++) {
					const e = this.items[t];
					e.sortData.random = Math.random();
				}
				(this.options.sortBy = 'random'), this._sort(), this._layout();
			}),
			(l._noTransition = function (t, e) {
				const i = this.options.transitionDuration;
				this.options.transitionDuration = 0;
				const o = t.apply(this, e);
				return (this.options.transitionDuration = i), o;
			}),
			(l.getFilteredItemElements = function () {
				return this.filteredItems.map(function (t) {
					return t.element;
				});
			}),
			d
		);
	});
