(function (e) {
	if (typeof define === 'function' && define.amd && define.amd.jQuery) {
		define(['jquery'], e);
	} else {
		e(jQuery);
	}
})(function (e) {
	function N(t) {
		if (
			t &&
			t.allowPageScroll === undefined &&
			(t.swipe !== undefined || t.swipeStatus !== undefined)
		) {
			t.allowPageScroll = u;
		}
		if (t.click !== undefined && t.tap === undefined) {
			t.tap = t.click;
		}
		if (!t) {
			t = {};
		}
		t = e.extend({}, e.fn.swipe.defaults, t);
		return this.each(function () {
			const n = e(this);
			let r = n.data(x);
			if (!r) {
				r = new C(this, t);
				n.data(x, r);
			}
		});
	}
	function C(T, N) {
		function nt(t) {
			if (jt()) {
				return;
			}
			if (e(t.target).closest(N.excludedElements, X).length > 0) {
				return;
			}
			const n = t.originalEvent ? t.originalEvent : t;
			let r,
				i = S ? n.touches[0] : n;
			V = y;
			if (S) {
				$ = n.touches.length;
			}
			P = 0;
			H = null;
			z = null;
			B = 0;
			F = 0;
			I = 0;
			R = 1;
			U = 0;
			J = Ut();
			W = Xt();
			Ht();
			if (!S || $ === N.fingers || N.fingers === m || gt()) {
				It(0, i);
				K = en();
				if ($ == 2) {
					It(1, n.touches[1]);
					F = I = Jt(J[0].start, J[1].start);
				}
				if (N.swipeStatus || N.pinchStatus) {
					r = ft(n, V);
				}
			} else {
				r = false;
			}
			if (r === false) {
				V = E;
				ft(n, V);
				return r;
			}
			Ft(true);

			return null;
		}
		function rt(e) {
			const t = e.originalEvent ? e.originalEvent : e;
			if (V === w || V === E || Bt()) {
				return;
			}
			let n,
				r = S ? t.touches[0] : t;
			const i = qt(r);
			Q = en();
			if (S) {
				$ = t.touches.length;
			}
			V = b;
			if ($ == 2) {
				if (F == 0) {
					It(1, t.touches[1]);
					F = I = Jt(J[0].start, J[1].start);
				} else {
					qt(t.touches[1]);
					I = Jt(J[0].end, J[1].end);
					z = Qt(J[0].end, J[1].end);
				}
				R = Kt(F, I);
				U = Math.abs(F - I);
			}
			if ($ === N.fingers || N.fingers === m || !S || gt()) {
				H = Zt(i.start, i.end);
				vt(e, H);
				P = Gt(i.start, i.end);
				B = $t();
				zt(H, P);
				if (N.swipeStatus || N.pinchStatus) {
					n = ft(t, V);
				}
				if (!N.triggerOnTouchEnd || N.triggerOnTouchLeave) {
					let s = true;
					if (N.triggerOnTouchLeave) {
						const o = tn(this);
						s = nn(i.end, o);
					}
					if (!N.triggerOnTouchEnd && s) {
						V = at(b);
					} else if (N.triggerOnTouchLeave && !s) {
						V = at(w);
					}
					if (V == E || V == w) {
						ft(t, V);
					}
				}
			} else {
				V = E;
				ft(t, V);
			}
			if (n === false) {
				V = E;
				ft(t, V);
			}
		}
		function it(e) {
			const t = e.originalEvent;
			if (S) {
				if (t.touches.length > 0) {
					Pt();
					return true;
				}
			}
			if (Bt()) {
				$ = Y;
			}
			e.preventDefault();
			Q = en();
			B = $t();
			if (ht()) {
				V = E;
				ft(t, V);
			} else if (
				N.triggerOnTouchEnd ||
				(N.triggerOnTouchEnd == false && V === b)
			) {
				V = w;
				ft(t, V);
			} else if (!N.triggerOnTouchEnd && Tt()) {
				V = w;
				lt(t, V, c);
			} else if (V === b) {
				V = E;
				ft(t, V);
			}
			Ft(false);
			return null;
		}
		function st() {
			$ = 0;
			Q = 0;
			K = 0;
			F = 0;
			I = 0;
			R = 1;
			Ht();
			Ft(false);
		}
		function ot(e) {
			const t = e.originalEvent;
			if (N.triggerOnTouchLeave) {
				V = at(w);
				ft(t, V);
			}
		}
		function ut() {
			X.unbind(L, nt);
			X.unbind(D, st);
			X.unbind(O, rt);
			X.unbind(M, it);
			if (_) {
				X.unbind(_, ot);
			}
			Ft(false);
		}
		function at(e) {
			let t = e;
			const n = dt();
			const r = ct();
			const i = ht();
			if (!n || i) {
				t = E;
			} else if (
				r &&
				e == b &&
				(!N.triggerOnTouchEnd || N.triggerOnTouchLeave)
			) {
				t = w;
			} else if (!r && e == w && N.triggerOnTouchLeave) {
				t = E;
			}
			return t;
		}
		function ft(e, t) {
			let n;
			if (Et() || wt()) {
				n = lt(e, t, f);
			} else if ((yt() || gt()) && n !== false) {
				n = lt(e, t, l);
			}
			if (_t() && n !== false) {
				n = lt(e, t, h);
			} else if (Dt() && n !== false) {
				n = lt(e, t, p);
			} else if (Mt() && n !== false) {
				n = lt(e, t, c);
			}
			if (t === E) {
				st(e);
			}
			if (t === w) {
				if (S) {
					if (e.touches.length == 0) {
						st(e);
					}
				} else {
					st(e);
				}
			}
			return n;
		}
		function lt(u, a, d) {
			let v;
			if (d == f) {
				X.trigger('swipeStatus', [a, H || null, P || 0, B || 0, $]);
				if (N.swipeStatus) {
					v = N.swipeStatus.call(
						X,
						u,
						a,
						H || null,
						P || 0,
						B || 0,
						$
					);
					if (v === false) {
						return false;
					}
				}
				if (a == w && bt()) {
					X.trigger('swipe', [H, P, B, $]);
					if (N.swipe) {
						v = N.swipe.call(X, u, H, P, B, $);
						if (v === false) {
							return false;
						}
					}
					switch (H) {
						case t:
							X.trigger('swipeLeft', [H, P, B, $]);
							if (N.swipeLeft) {
								v = N.swipeLeft.call(X, u, H, P, B, $);
							}
							break;
						case n:
							X.trigger('swipeRight', [H, P, B, $]);
							if (N.swipeRight) {
								v = N.swipeRight.call(X, u, H, P, B, $);
							}
							break;
						case r:
							X.trigger('swipeUp', [H, P, B, $]);
							if (N.swipeUp) {
								v = N.swipeUp.call(X, u, H, P, B, $);
							}
							break;
						case i:
							X.trigger('swipeDown', [H, P, B, $]);
							if (N.swipeDown) {
								v = N.swipeDown.call(X, u, H, P, B, $);
							}
							break;
					}
				}
			}
			if (d == l) {
				X.trigger('pinchStatus', [a, z || null, U || 0, B || 0, $, R]);
				if (N.pinchStatus) {
					v = N.pinchStatus.call(
						X,
						u,
						a,
						z || null,
						U || 0,
						B || 0,
						$,
						R
					);
					if (v === false) {
						return false;
					}
				}
				if (a == w && mt()) {
					switch (z) {
						case s:
							X.trigger('pinchIn', [
								z || null,
								U || 0,
								B || 0,
								$,
								R,
							]);
							if (N.pinchIn) {
								v = N.pinchIn.call(
									X,
									u,
									z || null,
									U || 0,
									B || 0,
									$,
									R
								);
							}
							break;
						case o:
							X.trigger('pinchOut', [
								z || null,
								U || 0,
								B || 0,
								$,
								R,
							]);
							if (N.pinchOut) {
								v = N.pinchOut.call(
									X,
									u,
									z || null,
									U || 0,
									B || 0,
									$,
									R
								);
							}
							break;
					}
				}
			}
			if (d == c) {
				if (a === E || a === w) {
					clearTimeout(et);
					if (Nt() && !Lt()) {
						Z = en();
						et = setTimeout(
							e.proxy(function () {
								Z = null;
								X.trigger('tap', [u.target]);
								if (N.tap) {
									v = N.tap.call(X, u, u.target);
								}
							}, this),
							N.doubleTapThreshold
						);
					} else {
						Z = null;
						X.trigger('tap', [u.target]);
						if (N.tap) {
							v = N.tap.call(X, u, u.target);
						}
					}
				}
			} else if (d == h) {
				if (a === E || a === w) {
					clearTimeout(et);
					Z = null;
					X.trigger('doubletap', [u.target]);
					if (N.doubleTap) {
						v = N.doubleTap.call(X, u, u.target);
					}
				}
			} else if (d == p) {
				if (a === E || a === w) {
					clearTimeout(et);
					Z = null;
					X.trigger('longtap', [u.target]);
					if (N.longTap) {
						v = N.longTap.call(X, u, u.target);
					}
				}
			}
			return v;
		}
		function ct() {
			let e = true;
			if (N.threshold !== null) {
				e = P >= N.threshold;
			}
			return e;
		}
		function ht() {
			let e = false;
			if (N.cancelThreshold !== null && H !== null) {
				e = Wt(H) - P >= N.cancelThreshold;
			}
			return e;
		}
		function pt() {
			if (N.pinchThreshold !== null) {
				return U >= N.pinchThreshold;
			}
			return true;
		}
		function dt() {
			let e;
			if (N.maxTimeThreshold) {
				if (B >= N.maxTimeThreshold) {
					e = false;
				} else {
					e = true;
				}
			} else {
				e = true;
			}
			return e;
		}
		function vt(e, s) {
			if (N.allowPageScroll === u || gt()) {
				e.preventDefault();
			} else {
				const o = N.allowPageScroll === a;
				switch (s) {
					case t:
						if (
							(N.swipeLeft && o) ||
							(!o && N.allowPageScroll != d)
						) {
							e.preventDefault();
						}
						break;
					case n:
						if (
							(N.swipeRight && o) ||
							(!o && N.allowPageScroll != d)
						) {
							e.preventDefault();
						}
						break;
					case r:
						if (
							(N.swipeUp && o) ||
							(!o && N.allowPageScroll != v)
						) {
							e.preventDefault();
						}
						break;
					case i:
						if (
							(N.swipeDown && o) ||
							(!o && N.allowPageScroll != v)
						) {
							e.preventDefault();
						}
						break;
				}
			}
		}
		function mt() {
			const e = St();
			const t = xt();
			const n = pt();
			return e && t && n;
		}
		function gt() {
			return !!(N.pinchStatus || N.pinchIn || N.pinchOut);
		}
		function yt() {
			return !!(mt() && gt());
		}
		function bt() {
			const e = dt();
			const t = ct();
			const n = St();
			const r = xt();
			const i = ht();
			const s = !i && r && n && t && e;
			return s;
		}
		function wt() {
			return !!(
				N.swipe ||
				N.swipeStatus ||
				N.swipeLeft ||
				N.swipeRight ||
				N.swipeUp ||
				N.swipeDown
			);
		}
		function Et() {
			return !!(bt() && wt());
		}
		function St() {
			return $ === N.fingers || N.fingers === m || !S;
		}
		function xt() {
			return J[0].end.x !== 0;
		}
		function Tt() {
			return !!N.tap;
		}
		function Nt() {
			return !!N.doubleTap;
		}
		function Ct() {
			return !!N.longTap;
		}
		function kt() {
			if (Z == null) {
				return false;
			}
			const e = en();
			return Nt() && e - Z <= N.doubleTapThreshold;
		}
		function Lt() {
			return kt();
		}
		function At() {
			return ($ === 1 || !S) && (isNaN(P) || P === 0);
		}
		function Ot() {
			return B > N.longTapThreshold && P < g;
		}
		function Mt() {
			return !!(At() && Tt());
		}
		function _t() {
			return !!(kt() && Nt());
		}
		function Dt() {
			return !!(Ot() && Ct());
		}
		function Pt() {
			G = en();
			Y = event.touches.length + 1;
		}
		function Ht() {
			G = 0;
			Y = 0;
		}
		function Bt() {
			let e = false;
			if (G) {
				const t = en() - G;
				if (t <= N.fingerReleaseThreshold) {
					e = true;
				}
			}
			return e;
		}
		function jt() {
			return !!(X.data(x + '_intouch') === true);
		}
		function Ft(e) {
			if (e === true) {
				X.bind(O, rt);
				X.bind(M, it);
				if (_) {
					X.bind(_, ot);
				}
			} else {
				X.unbind(O, rt, false);
				X.unbind(M, it, false);
				if (_) {
					X.unbind(_, ot, false);
				}
			}
			X.data(x + '_intouch', e === true);
		}
		function It(e, t) {
			const n = t.identifier !== undefined ? t.identifier : 0;
			J[e].identifier = n;
			J[e].start.x = J[e].end.x = t.pageX || t.clientX;
			J[e].start.y = J[e].end.y = t.pageY || t.clientY;
			return J[e];
		}
		function qt(e) {
			const t = e.identifier !== undefined ? e.identifier : 0;
			const n = Rt(t);
			n.end.x = e.pageX || e.clientX;
			n.end.y = e.pageY || e.clientY;
			return n;
		}
		function Rt(e) {
			for (let t = 0; t < J.length; t++) {
				if (J[t].identifier == e) {
					return J[t];
				}
			}
		}
		function Ut() {
			const e = [];
			for (let t = 0; t <= 5; t++) {
				e.push({
					start: { x: 0, y: 0 },
					end: { x: 0, y: 0 },
					identifier: 0,
				});
			}
			return e;
		}
		function zt(e, t) {
			t = Math.max(t, Wt(e));
			W[e].distance = t;
		}
		function Wt(e) {
			if (W[e]) {
				return W[e].distance;
			}
			return undefined;
		}
		function Xt() {
			const e = {};
			e[t] = Vt(t);
			e[n] = Vt(n);
			e[r] = Vt(r);
			e[i] = Vt(i);
			return e;
		}
		function Vt(e) {
			return { direction: e, distance: 0 };
		}
		function $t() {
			return Q - K;
		}
		function Jt(e, t) {
			const n = Math.abs(e.x - t.x);
			const r = Math.abs(e.y - t.y);
			return Math.round(Math.sqrt(n * n + r * r));
		}
		function Kt(e, t) {
			const n = (t / e) * 1;
			return n.toFixed(2);
		}
		function Qt() {
			if (R < 1) {
				return o;
			}
			return s;
		}
		function Gt(e, t) {
			return Math.round(
				Math.sqrt(Math.pow(t.x - e.x, 2) + Math.pow(t.y - e.y, 2))
			);
		}
		function Yt(e, t) {
			const n = e.x - t.x;
			const r = t.y - e.y;
			const i = Math.atan2(r, n);
			let s = Math.round((i * 180) / Math.PI);
			if (s < 0) {
				s = 360 - Math.abs(s);
			}
			return s;
		}
		function Zt(e, s) {
			const o = Yt(e, s);
			if (o <= 45 && o >= 0) {
				return t;
			}
			if (o <= 360 && o >= 315) {
				return t;
			}
			if (o >= 135 && o <= 225) {
				return n;
			}
			if (o > 45 && o < 135) {
				return i;
			}
			return r;
		}
		function en() {
			const e = new Date();
			return e.getTime();
		}
		function tn(t) {
			t = e(t);
			const n = t.offset();
			const r = {
				left: n.left,
				right: n.left + t.outerWidth(),
				top: n.top,
				bottom: n.top + t.outerHeight(),
			};
			return r;
		}
		function nn(e, t) {
			return (
				e.x > t.left && e.x < t.right && e.y > t.top && e.y < t.bottom
			);
		}
		var C = S || !N.fallbackToMouseEvents,
			L = C ? 'touchstart' : 'mousedown',
			O = C ? 'touchmove' : 'mousemove',
			M = C ? 'touchend' : 'mouseup',
			_ = C ? null : 'mouseleave',
			D = 'touchcancel';
		var P = 0,
			H = null,
			B = 0,
			F = 0,
			I = 0,
			R = 1,
			U = 0,
			z = 0,
			W = null;
		var X = e(T);
		var V = 'start';
		var $ = 0;
		var J = null;
		var K = 0,
			Q = 0,
			G = 0,
			Y = 0,
			Z = 0;
		var et = null;
		try {
			X.bind(L, nt);
			X.bind(D, st);
		} catch (tt) {
			e.error('events not supported ' + L + ',' + D + ' on jQuery.swipe');
		}
		this.enable = function () {
			X.bind(L, nt);
			X.bind(D, st);
			return X;
		};
		this.disable = function () {
			ut();
			return X;
		};
		this.destroy = function () {
			ut();
			X.data(x, null);
			return X;
		};
		this.option = function (t, n) {
			if (N[t] !== undefined) {
				if (n === undefined) {
					return N[t];
				}
				N[t] = n;
			} else {
				e.error(
					'Option ' + t + ' does not exist on jQuery.swipe.options'
				);
			}
			return null;
		};
	}
	var t = 'left',
		n = 'right',
		r = 'up',
		i = 'down',
		s = 'in',
		o = 'out',
		u = 'none',
		a = 'auto',
		f = 'swipe',
		l = 'pinch',
		c = 'tap',
		h = 'doubletap',
		p = 'longtap',
		d = 'horizontal',
		v = 'vertical',
		m = 'all',
		g = 10,
		y = 'start',
		b = 'move',
		w = 'end',
		E = 'cancel',
		S = 'ontouchstart' in window,
		x = 'TouchSwipe';
	const T = {
		fingers: 1,
		threshold: 75,
		cancelThreshold: null,
		pinchThreshold: 20,
		maxTimeThreshold: null,
		fingerReleaseThreshold: 250,
		longTapThreshold: 500,
		doubleTapThreshold: 200,
		swipe: null,
		swipeLeft: null,
		swipeRight: null,
		swipeUp: null,
		swipeDown: null,
		swipeStatus: null,
		pinchIn: null,
		pinchOut: null,
		pinchStatus: null,
		click: null,
		tap: null,
		doubleTap: null,
		longTap: null,
		triggerOnTouchEnd: true,
		triggerOnTouchLeave: false,
		allowPageScroll: 'auto',
		fallbackToMouseEvents: true,
		excludedElements: 'label, button, input, select, textarea, a, .noSwipe',
	};
	e.fn.swipe = function (t) {
		const n = e(this),
			r = n.data(x);
		if (r && typeof t === 'string') {
			if (r[t]) {
				return r[t].apply(
					this,
					Array.prototype.slice.call(arguments, 1)
				);
			}
			e.error('Method ' + t + ' does not exist on jQuery.swipe');
		} else if (!r && (typeof t === 'object' || !t)) {
			return N.apply(this, arguments);
		}
		return n;
	};
	e.fn.swipe.defaults = T;
	e.fn.swipe.phases = {
		PHASE_START: y,
		PHASE_MOVE: b,
		PHASE_END: w,
		PHASE_CANCEL: E,
	};
	e.fn.swipe.directions = {
		LEFT: t,
		RIGHT: n,
		UP: r,
		DOWN: i,
		IN: s,
		OUT: o,
	};
	e.fn.swipe.pageScroll = { NONE: u, HORIZONTAL: d, VERTICAL: v, AUTO: a };
	e.fn.swipe.fingers = { ONE: 1, TWO: 2, THREE: 3, ALL: m };
});
